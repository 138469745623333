<template>
  <v-autocomplete
    :items="options"
    :label="label"
    :value="editValue"
    :rules="rules"
    @change="change"
  />
</template>

<script>
export default {
  name: "GsLocaleSelector",
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    editValue: null
  }),
  computed: {
    options() {
      const options = this.getAllLocales();
      const options_sorted = this.arrSortAlphabetically(options, {
        key: "text"
      });
      return options_sorted;
    }
  },
  watch: {
    value(v) {
      this.setEditValue(v);
    }
  },
  created() {
    this.setEditValue(this.value);
  },
  // async created() {
  //   const user = await memory.getItemAsync("user");
  //   let defaultTimezone = null;
  //   if (user) {
  //     defaultTimezone = this.options.find(p => p === user.timezone);
  //   } else {
  //     defaultTimezone = dayjs.tz.guess(true);
  //   }
  //   this.$emit("input", defaultTimezone);
  // },
  methods: {
    setEditValue(value) {
      this.editValue = value;
    },
    change(newValue) {
      this.editValue = newValue;
      this.$emit("input", this.editValue);
      this.$emit("change", this.editValue);
    }
  }
};
</script>
