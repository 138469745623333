<template>
  <v-container fluid>
    <!-- <v-card flat class="mb-3"> -->
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="customer"
    >
      <v-card>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('name')"
                v-model="customer.name"
                type="text"
                maxlength="256"
                :rules="formRules.required"
              />
              <v-text-field
                :label="$t('code') + ' (' + $t('optional') + ')'"
                v-model="customer.code"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <GsCountrySelector
                :label="$t('country')"
                v-model="customer.countryCode"
                :rules="formRules.required"
              />
              <v-text-field
                :label="$t('zipCode') + ' (' + $t('optional') + ')'"
                v-model="customer.zipCode"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <v-text-field
                :label="$t('city') + ' (' + $t('optional') + ')'"
                v-model="customer.city"
                maxlength="256"
                :rules="formRules.optional"
              />
              <v-text-field
                :label="$t('street') + ' (' + $t('optional') + ')'"
                v-model="customer.street"
                maxlength="256"
                :rules="formRules.optional"
              />
              <v-text-field
                :label="$t('taxNumber') + ' (' + $t('optional') + ')'"
                v-model="customer.taxNumber"
                type="text"
                maxlength="256"
                :rules="formRules.optionalMin8"
              />
            </div>

            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('email') + ' (' + $t('optional') + ')'"
                v-model="customer.email"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <v-text-field
                :label="$t('mobile') + ' (' + $t('optional') + ')'"
                v-model="customer.mobile"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <GsLocaleSelector
                :label="$t('locale')"
                v-model="customer.locale"
                :rules="formRules.required"
              />
              <!-- <GsTimeZone
                :label="$t('timezone')"
                v-model="customer.timezone"
                :rules="formRules.required"
              /> -->
              <GsInfiniteDropdown
                :config="customerSelectionConfig"
                v-model="customer.ownedBy"
              />
              <MFCustomersDropdown
                :label="$t('mfCustomer') + ' (' + $t('optional') + ')'"
                v-model="customer.mfCustomer"
                :rules="formRules.optional"
                :ownedBy="customer.ownedBy"
              />
              <v-textarea
                :label="$t('comment') + ' (' + $t('optional') + ')'"
                v-model="customer.comment"
                type="text"
                maxlength="256"
                rows="6"
                :rules="formRules.optional"
              />
            </div>
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
// import GsTimeZone from "@/components/FormComponents/GsTimeZone";
import GsCountrySelector from "@/components/FormComponents/GsCountrySelector";
import GsLocaleSelector from "@/components/FormComponents/GsLocaleSelector";
import MFCustomersDropdown from "@/components/FormComponents/MFCustomersDropdown";
import { GsInfiniteDropdown } from "ngt-frontend-core";

export default {
  name: "Customer",
  components: {
    // GsTimeZone,
    GsCountrySelector,
    GsLocaleSelector,
    MFCustomersDropdown,
    GsInfiniteDropdown
  },
  data() {
    return {
      mode: null,
      customer: null,
      customer_orig: null,
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true],
        optionalMin8: [value => (value ? value.length >= 8 : true)]
      },
      valid: true,
      error: null,
      loading: false
    };
  },
  computed: {
    customerSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/customers",
          sort: "name:asc",
          fields: "name,_uri",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "_uri"
          }
        },
        label: this.$t("ownedBy"),
        limit: 50,
        page: 0,
        rules: this.formRules.required,
        value: this.customer?.ownedBy
      };
    }
  },
  watch: {
    "$route.params.customerId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createCustomer();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadCustomer(v);
      }
    }
  },
  async mounted() {
    // check if customers are available?
    const checkCustomers = this.$store.getters.customers();
    if (checkCustomers || !checkCustomers?.length) {
      this.$store.dispatch("getCustomers");
    }
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createCustomer() {
      this.customer = {
        name: null,
        code: null,
        taxNumber: null,
        countryCode: null,
        zipCode: null,
        city: null,
        street: null,
        email: null,
        mobile: null,
        comment: null,
        locale: null,
        ownedBy: this.$store.state.user.ownedBy._uri,
        mfCustomer: null,
        enabled: 1
      };
    },
    async loadCustomer(customerId) {
      const url = `${this.$store.state.idp_api_url}/customers/${customerId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.customer = response.data;
        this.customer_orig = this.cloneObj(response.data);
        // console.log(this.customer)
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/customers`;
      if (this.mode == "edit") url += `/${this.customer.customerId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";

      const fields = [
        "name",
        "code",
        "taxNumber",
        "countryCode",
        "zipCode",
        "city",
        "street",
        "locale",
        "email",
        "mobile",
        "comment",
        "mfCustomer",
        "ownedBy"
      ];

      let data = {};
      if (this.mode == "create") {
        for (let field of fields) {
          if (this.customer[field]) data[field] = this.customer[field];
        }
      } else {
        for (let field of fields) {
          // changed?
          // console.log(field, this.customer[field], this.customer_orig[field])
          if (this.customer[field] != this.customer_orig[field])
            data[field] = this.customer[field] ? this.customer[field] : null;
        }
      }

      if (Object.keys(data).length === 0) {
        this.$router.go(-1);
        return;
      }

      // data.group = this.customer.group || [];

      // console.log("to be saved ", data)

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$store.dispatch("getCustomers");
          this.$router.go(-1);
        }

        // this.customer = response.data
        // console.log(this.customer)
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
