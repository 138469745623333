<template>
  <div>
    <v-autocomplete
      :label="label"
      :items="mappedItems"
      item-text="title"
      item-value="value"
      v-model="editValue"
      :suffix="loaded"
      :search-input="config.searchText"
      clearable
      hide-no-data
      @keyup="searchData"
      @change="showChange"
      :disabled="disabled"
    >
      <template v-slot:item="data">
        <template v-if="!isObject(data.item)">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.title"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.subtitle"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template v-if="isMore" v-slot:append-item>
        <div class="text-center" v-observe-visibility="handleInfinityScroll">
          <v-progress-circular
            indeterminate
            color="primary"
            class="ma-4"
          ></v-progress-circular>
          <span>{{ $t("loading") }}</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;

const URL =
  "/resources/mf-customer/customers?sort=customerName:asc&fields=customerId,customerName,_idMap,_uri";
// const URL = "/resources/mf-customer/customers";

export default {
  name: "MFCustomersDropdown",
  props: {
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    value: {
      type: String,
      default: null
    },
    ownedBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      editValue: null,
      items: [],
      isMore: true,
      total: 0,
      config: {
        page: 0,
        limit: 50,
        searchText: null
      }
    };
  },
  computed: {
    API() {
      if (!this.ownedBy) return "";
      const filter = this.config.searchText
        ? "&filter=customerName:ic:" +
          encodeURIComponent(this.config.searchText)
        : "";
      // const ownedBy = `&ownedBy=${encodeURIComponent(this.ownedBy)}`;
      // return `${URL}&limit=${this.config.limit}&page=${this.config.page}${filter}${ownedBy}`;
      return `${URL}&page=${this.config.page}&limit=${this.config.limit}${filter}`;
    },
    mappedItems() {
      if (!this.items.length) {
        return [];
      }
      return this.items.map(item => {
        return {
          id: item?.customerId,
          title: item?.customerName,
          subtitle: "",
          value: item?._uri
        };
      });
    },
    loaded() {
      return `${this.items.length} / ${this.total}`;
    },
    disabled() {
      return !this.ownedBy;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.getResponse(`${URL}&filter=_uri:eq:${newVal}`);
          this.editValue = newVal;
        } else {
          this.editValue = null;
        }
      }
    },
    API(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.getResponse(this.API);
    }
  },
  created() {
    this.getResponse(this.API);
  },
  methods: {
    isObject(item) {
      return typeof item === "object";
    },
    handleInfinityScroll(isVisible) {
      if (!isVisible) {
        return;
      }
      this.config.page++;
    },
    async getResponse(url, reset = false) {
      if (!url) {
        this.items = [];
        this.total = 0;
        this.isMore = false;
        this.config.page = 0;
        return;
      }

      try {
        const response = await callBffAPI({
          url,
          method: "GET",
          bffPrefix: false
        });
        if (response.status != 200) {
          this.items = [];
          this.total = 0;
          this.isMore = false;
          this.config.page = 0;
          this.editValue = null;
          return;
        }
        if (reset) {
          this.items = [];
          this.total = 0;
          this.isMore = false;
          this.config.page = 0;
        }
        if (response?.data.length) {
          this.items.push(...response.data);
          this.total = response.headers["x-total-count"];
          this.isMore = true;
        } else {
          this.isMore = false;
        }
      } catch {
        this.items = [];
        this.total = 0;
        this.isMore = false;
        this.config.page = 0;
        this.editValue = null;
      }
    },
    async searchData(event) {
      const queryText = event.srcElement._value;
      if (queryText?.length < 3) {
        return;
      }
      if (this.config.searchText === queryText) {
        return;
      }
      // reset
      this.items = [];
      this.total = 0;
      this.isMore = false;
      this.config = {
        ...this.config,
        searchText: queryText,
        page: 0
      };
    },
    showChange(newValue) {
      this.config.searchText = null;
      this.$emit("input", this.editValue);
    }
  }
};
</script>
